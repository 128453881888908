import * as d3 from "d3";
import {originData} from "./data";

export function createHistogram() {
    const svg = d3.select('#histogram-container')
        .append('svg')
        .attr('width', 600)
        .attr('height', 375);

    const data = originData;

// 创建柱状图的比例尺
    const xScale = d3.scaleBand()
        .domain(data.map((d) => d.text))
        .range([0, 600])
        .padding(0.1);

    const yScale = d3.scaleLinear()
        .domain([0, d3.max(data, (d) => d.size)])
        .range([0, 300]);

// 创建柱状图的柱子
    svg.selectAll('rect')
        .data(data)
        .enter()
        .append('rect')
        // @ts-ignore
        .attr('x', (d) => xScale(d.text))
        .attr('y', (d) => 375 - yScale(d.size))
        .attr('width', xScale.bandwidth())
        .attr('height', (d) => yScale(d.size))
        .attr('fill', 'steelblue');

    // 创建柱状图的标签
    svg.selectAll('text')
        .data(data)
        .enter()
        .append('text')
        .text((d) => d.size)
        .attr('x', (d) => xScale(d.text) + xScale.bandwidth() / 2)
        .attr('y', (d) => 375 - yScale(d.size) - 5)
        .attr('text-anchor', 'middle')
        .attr('fill', 'black');

    // 创建柱状图的标签容器
    const labelContainer = svg.append('g')
        .attr('class', 'label-container');

// 创建柱状图的标签
    const labels = labelContainer.selectAll('text')
        .data(data)
        .enter()
        .append('text')
        .text((d) => d.text)
        .attr('x', (d) => xScale(d.text) + xScale.bandwidth() / 2)
        .attr('y', (d) => 350 - yScale(d.size) - 5)
        .attr('text-anchor', 'middle')
        .attr('fill', 'black')
        .attr('font-weight', 'bold')
        .style('opacity', 0); // 初始时设置标签透明度为0

// 鼠标悬停时显示标签
    svg.selectAll('rect')
        // @ts-ignore
        .on('mouseover', (event, d) => {
            labels.filter((labelData) => labelData.text === d.text)
                .style('opacity', 1);
        })
        .on('mouseout', () => {
            labels.style('opacity', 0);
        });
}