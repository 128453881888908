import * as d3 from "d3";
import cloud from "d3-cloud";
import {originData} from "./data";


function getWords() {
    return originData.map(function (d) {
        return {text: d.text, size: 10 + d.size * 7};
    })
}

export function createWordCloud() {
    let wordCloud = cloud()
        .size([500, 400])
        .words(getWords())
        .padding(5)
        .rotate(function () {
            return ~~(Math.random() * 2) * 90;
        })
        .font("Source Han Sans CN")
        .fontSize(function (d) {
            return d.size === undefined ? 14 : d.size;
        })
        .on("end", draw);

    wordCloud.start();

    function draw(words) {
        d3.select("#word-cloud-container").append("svg")
            .attr("width", wordCloud.size()[0])
            .attr("height", wordCloud.size()[1])
            .append("g")
            .attr("transform", "translate(" + wordCloud.size()[0] / 2 + "," + wordCloud.size()[1] / 2 + ")")
            .selectAll("text")
            .data(words)
            .enter().append("text")
            .style("font-size", function (d) {
                return d.size + "px";
            })
            .style("font-family", "Source Han Sans CN")
            .attr("text-anchor", "middle")
            .attr("transform", function (d) {
                return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
            })
            .text(function (d) {
                return d.text;
            });
    }
}