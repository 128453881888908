<template>
    <t-date-range-picker clearable size="medium" allowInput class="mr-2" @change="changeTime"/>
    <t-select autoWidth label="关键词：" class="w-auto inline-block mr-2" v-model="tag">
        <t-option v-for="tag in tagsList" :key="tag" :value="tag" :label="tag" @click="changeTag(tag)"/>
    </t-select>
    <div ref="mapContainer" style="width: 100%; height: 500px;" @click="handleMapClick">
    </div>
</template>

<script>
import * as echarts from 'echarts';
import {ref, onMounted} from 'vue'
import 'echarts4/map/js/china'
import dayjs from "dayjs-ext";
import axios from "core-js/internals/queue";

export default {
    name: 'MapChart',
    data() {
        return {
            tagsList: ["保供", "健身", "压茬", "可乐", "咖啡", "团长", "封校", "小区", "研判", "移民",
                "自治", "辟谣", "刘耕宏", "合围区", "外国人", "必需品", "无差别", "楼组长", "全力以复",
                "历史无阳", "哄抬物价", "外籍人士", "有序放开", "有序解封", "有限流动", "点式复工", "解封不解防"],
            csvData: null,
            tag: null
        };
    },
    methods: {},
    setup() {
        const mapContainer = ref(null)
        let data = [
            {name: "南海诸岛", value: 0},
            {name: "北京", value: 0},
            {name: "天津", value: 0},
            {name: "上海", value: 0},
            {name: "重庆", value: 0},
            {name: "河北", value: 0},
            {name: "河南", value: 0},
            {name: "云南", value: 0},
            {name: "辽宁", value: 0},
            {name: "黑龙江", value: 0},
            {name: "湖南", value: 0},
            {name: "安徽", value: 0},
            {name: "山东", value: 0},
            {name: "新疆", value: 0},
            {name: "江苏", value: 0},
            {name: "浙江", value: 0},
            {name: "江西", value: 0},
            {name: "湖北", value: 0},
            {name: "广西", value: 0},
            {name: "甘肃", value: 0},
            {name: "山西", value: 0},
            {name: "内蒙古", value: 0},
            {name: "陕西", value: 0},
            {name: "吉林", value: 0},
            {name: "福建", value: 0},
            {name: "贵州", value: 0},
            {name: "广东", value: 0},
            {name: "青海", value: 0},
            {name: "西藏", value: 0},
            {name: "四川", value: 0},
            {name: "宁夏", value: 0},
            {name: "海南", value: 0},
            {name: "台湾", value: 0},
            {name: "香港", value: 0},
            {name: "澳门", value: 0}
        ];
        let myChart;
        onMounted(() => {
            if (!echarts) {
                console.error('ECharts is not initialized.');
                return;
            }
            // 初始化 ECharts 地图
            myChart = echarts.init(mapContainer.value)
            myChart.setOption({
                // ECharts 配置项
                geo: {
                    map: 'china',
                    roam: true,
                    label: {
                        show: true,
                        emphasis: {
                            show: false,
                        },
                    },
                    itemStyle: {
                        normal: {
                            areaColor: '#dddddd',
                            borderColor: '#ffffff',
                        },
                        emphasis: {
                            areaColor: '#aaaaaa',
                        },
                    },
                },
                visualMap: {
                    min: 0,
                    max: 10,
                    left: 'left',
                    top: 'bottom',
                    text: ['高', '低'],//取值范围的文字
                    inRange: {
                        color: ['#fae3c4', '#e58166']//取值范围的颜色
                    },
                    show: true//图注
                },
                tooltip: {
                    trigger: 'item', // 触发类型为数据项触发
                    formatter: function (params) {
                        // params 包含了事件的相关信息
                        // params.data 是当前鼠标悬停的省份的数据
                        const provinceName = params.name;
                        const provinceValue = params.value;

                        // 返回自定义的 tooltip 内容
                        return `${provinceName}: ${provinceValue}`;
                    },
                    backgroundColor: 'rgba(255, 255, 255, 0.9)', // tooltip 的背景颜色
                    borderColor: '#999', // tooltip 的边框颜色
                    borderWidth: 1, // tooltip 的边框宽度
                    padding: 10, // tooltip 内容的内边距
                    textStyle: {
                        color: '#333', // tooltip 文字颜色
                    },
                },
                series: [
                    {
                        name: '信息量',
                        type: 'map',
                        geoIndex: 0,
                        data: data
                    }
                ],

            })
        })

        function changeTag(newTag) {
            data.forEach(city => {
                city.value = 0;
            });
            this.tag = newTag;
            console.log(this.tag)
            fetchData(newTag)
        }

        let timeRange = ref([]);

        function changeTime(dateRange) {
            console.log(dateRange)
            timeRange.value = [dayjs(dateRange[0], {format: 'YYYY-MM-DD'}).toDate(),
                dayjs(dateRange[1], {format: 'YYYY-MM-DD'}).toDate()];
        }

        async function fetchData(newTag) {
            try {
                // 请替换为你的 CSV 文件路径
                // const response = await axios.get('./csv/新冠 防控政策 ' + this.tag + '_None_None.csv');
                fetch('./csv/新冠 防控政策 ' + newTag + '_None_None.csv')
                    .then(response => response.text())
                    .then(csvData => {
                            parseCSV(csvData);
                        }
                    )
            } catch (error) {
                console.error('Error fetching CSV data:', error);
            }
        }

        function parseCSV(csvContent) {
            const lines = csvContent.split('\n');
            const headers = lines[0].split(',');

            for (let i = 1; i < lines.length; i++) {
                const currentLine = lines[i].split(',');
                if (currentLine.length === headers.length) {
                    if (currentLine[9].toString() !== "") {
                        let curDate = dayjs(currentLine[9].replace("年", " ").replace("月", " ").replace("日", " "),
                            {format: "YYYY-MM-DD"}).toDate();
                        console.log(curDate)
                        if (curDate.getTime() < timeRange.value[0].getTime() ||
                            curDate.getTime() > timeRange.value[1].getTime())
                            continue;
                    }
                    const city = currentLine[7].trim();

                    if (city !== '' && city !== 'null' && city != null) {
                        console.log(city)
                        const foundCity = data.find(c => c.name === city);
                        console.log(foundCity)
                        if (foundCity !== undefined) {
                            foundCity.value++;
                        }
                    }
                }
            }
            console.log(data)
            myChart.setOption({
                series: [
                    {
                        data: data
                    }
                ]
            });
        }

        return {
            mapContainer,
            changeTag,
            changeTime
        }
    },
}
</script>

<style scoped>

</style>
