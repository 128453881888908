<template>
    <iframe v-if="isHomeToggled" src="https://workspace.easyv.cloud/shareScreen/eyJzY3JlZW5JZCI6MjQ5MzkwMX0="
            frameborder="0"
            style="width: 100%; height: 100%;"></iframe>
    <t-button v-if="isHomeToggled" theme="primary" @click="toggleHome"
              style="position: absolute; z-index: 1000; right: 20px; bottom: 20px;">
        更多功能
    </t-button>
    <t-layout class="h-full">
        <t-aside>
            <t-menu theme="light" default-value="map"
                    style="margin-right: 50px" height="100%" @change="changeHandler">
                <template #logo>
                    &nbsp;<span class="font-bold text-lg">数字化记忆灾害</span>
                </template>
                <t-menu-item value="home">
                    <template #icon>
                        <t-icon name="home"/>
                    </template>
                    首页视图
                </t-menu-item>
                <t-menu-item value="map">
                    <template #icon>
                        <t-icon name="earth"/>
                    </template>
                    地图索引
                </t-menu-item>
                <t-menu-item value="analytics">
                    <template #icon>
                        <t-icon name="chart-line"/>
                    </template>
                    统计与词云
                </t-menu-item>
                <t-menu-item value="ai">
                    <template #icon>
                        <t-icon name="questionnaire"/>
                    </template>
                    AI 问答
                </t-menu-item>
            </t-menu>
        </t-aside>
        <t-layout class="h-full overflow-scroll">
            <t-content class="p-4">
                <router-view></router-view>
            </t-content>
            <t-footer>Copyright @ 2023.</t-footer>
        </t-layout>
    </t-layout>
</template>


<script>
import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";

export default {
    name: 'App',
    components: {},
    setup: function () {
        const router = useRouter();
        const isHomeToggled = ref(true);
        const changeHandler = (active) => {
            if (active === 'home')
                isHomeToggled.value = true;
            else
                router.push(active);
        };
        const toggleHome = () => {
            isHomeToggled.value = !isHomeToggled.value;
        };
        return {
            toggleHome,
            isHomeToggled,
            changeHandler
        };
    }
}
</script>

<style>
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
}
</style>
