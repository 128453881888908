import * as d3 from 'd3';
import {originData} from "./data";

export function createPieChart() {

// SVG容器的宽度和高度
    const width = 300;
    const height = 400;

// 颜色比例尺
    const colorScale = d3.scaleOrdinal()
        .domain(originData.map((d) => d.label))
        .range(d3.schemeCategory10);

// 创建SVG容器
    const svg = d3.select('#piechart-container')
        .append('svg')
        .attr('width', width)
        .attr('height', height);

// 创建饼图的布局
    const pie = d3.pie()
        .value((d) => d.size);

// 生成饼图的路径
    const arc = d3.arc()
        .innerRadius(0)
        .outerRadius(Math.min(width, height) / 2 - 10);

// 创建饼图的扇形
    const arcs = svg.selectAll('path')
        .data(pie(originData))
        .enter()
        .append('path')
        .attr('d', arc)
        // @ts-ignore
        .attr('fill', (d) => colorScale(d.data.text))
        .attr('transform', `translate(${width / 2}, ${height / 2})`)
        .style('opacity', 1); // 初始时设置扇形透明度为0

// 鼠标悬停时显示标签
    arcs.on('mouseover', (_event, d) => {
        const centroid = arc.centroid(d);
        const x = centroid[0] + width / 2;
        const y = centroid[1] + height / 2;

        svg.append('text')
            .attr('class', 'label')
            .attr('x', x)
            .attr('y', y)
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(`${d.data.text}: ${d.data.size}`)
            .style('opacity', 1);
    })
        .on('mouseout', () => {
            svg.selectAll('.label').remove();
        });
}