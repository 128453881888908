<script setup>

import {createPieChart} from "@/components/analytics/piechart";
import {createHistogram} from "@/components/analytics/histogram";
import {createWordCloud} from "@/components/analytics/word-cloud";
import {setData} from "@/components/analytics/data";
import {ref} from "vue";
import dayjs from 'dayjs-ext';
import customParseFormat from 'dayjs-ext/plugin/customParseFormat';
import localizableFormat from 'dayjs-ext/plugin/localizableFormat';
import 'dayjs-ext/locale/zh-cn';

dayjs.extend(customParseFormat);

let tag = ref(''), content = ref(''), isRaw = ref(false);
const tagsList = ["保供", "健身", "压茬", "可乐", "咖啡", "团长", "封校", "小区", "研判", "移民",
    "自治", "辟谣", "刘耕宏", "合围区", "外国人", "必需品", "无差别", "楼组长", "全力以复",
    "历史无阳", "哄抬物价", "外籍人士", "有序放开", "有序解封", "有限流动", "点式复工", "解封不解防"];
const rawContentList = ['tag', 'date', 'ip address', 'author', 'Content'];
const removeFromContentList = [',', '，', '的', '和', '也', '与', '地', ':', '：', '?', '？', '!', '！', '、', '。', '(', ')', '（', '）', '[', ']', '【', '】', '“', '”', '"', '\'', '\'', '《', '》', '<', '>', '/', '、', ';', '；', '\n', '\t', ' ', '了', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '…'];
let timeRange = ref([]);

function changeContent(newContent, isRawContent) {
    content.value = newContent;
    isRaw.value = isRawContent;
    commitChange();
}

function changeTag(newTag) {
    tag.value = newTag;
    commitChange();
}

function commitChange() {
    if (tag.value === '' || content.value === '') {
        return;
    }

    let values = [];

    if (isRaw.value) {
        if (content.value !== "Content") {
            fetch('./csv/新冠 防控政策 ' + tag.value + '_None_None.csv')
                .then(response => response.text())
                .then(data => {
                    // 解析CSV数据
                    const rows = data.split('\n');
                    const headers = rows[0].split(',');

                    for (let i = 1; i < rows.length; i++) {
                        const row = rows[i].split(',');
                        if (row.length === headers.length) {
                            const obj = {};
                            for (let j = 0; j < headers.length; j++) {
                                obj[headers[j]] = row[j];
                            }
                            if (isRaw.value) {
                                if (timeRange.value.length !== 0) {
                                    if (obj["date"].toString() !== "") {
                                        let curDate = dayjs(obj["date"].replace("年", " ").replace("月", " ").replace("日", " "),
                                            {format: "YYYY MM DD HH:mm"}).toDate();
                                        if (curDate.getTime() < timeRange.value[0].getTime() ||
                                            curDate.getTime() > timeRange.value[1].getTime())
                                            continue;
                                    }
                                }
                                if (content.value === "author" || content.value === "ip address") {
                                    if (obj[content.value] !== undefined)
                                        if (obj[content.value] !== '' && obj[content.value] !== 'null' && obj[content.value] != null)
                                            values.push(obj[content.value]);
                                } else if (content.value === "tag") {
                                    if (obj[content.value] !== undefined)
                                        if (obj[content.value] !== '[]' && obj[content.value] !== 'null'
                                            && obj[content.value] != null && obj[content.value] !== "['网页链接']")
                                            values.push(obj[content.value].replace('[\'', '').replace('\']', ''));
                                } else if (content.value === "date") {
                                    if (obj[content.value] !== undefined)
                                        if (obj[content.value] !== '' && obj[content.value] !== 'null' && obj[content.value] != null)
                                            values.push(obj[content.value].substring(0, obj[content.value].length - 5));
                                }
                            }
                        }
                    }

                    parse(values);
                })
                .catch(error => {
                    alert('读取文件时发生错误:', error);
                    console.log(error);
                });
        } else {
            fetch('./seg/新冠 防控政策 ' + tag.value + '_None_None.csv_seg.txt')
                .then(response => response.text())
                .then(data => {
                    // 解析CSV数据
                    const rows = data.split('\n');

                    for (let i = 1; i < rows.length; i++) {
                        if (removeFromContentList.indexOf(rows[i]) === -1 && rows[i] !== '' && isNaN(Number(rows[i])))
                            values.push(rows[i]);
                    }

                    parse(values);
                })
                .catch(error => {
                    alert('读取文件时发生错误:', error);
                });
        }
    }
}

function parse(values) {
    const wordFrequency = {};

    values.sort();
    values.forEach((word) => {
        word = word.toLowerCase();
        if (word.length <= 1) return;
        if (wordFrequency[word]) {
            wordFrequency[word]++;
        } else {
            wordFrequency[word] = 1;
        }
    });

    //remove things with only very low frequency
    if (values.length > 40)
        for (let key in wordFrequency) {
            console.log(key, wordFrequency[key])
            if (wordFrequency[key] <= 1) {
                delete wordFrequency[key];
            }
        }
    const freqLen = Object.keys(wordFrequency).length;
    for (let key in wordFrequency) {
        console.log(key, wordFrequency[key])
        if (wordFrequency[key] <= freqLen / 50) {
            delete wordFrequency[key];
        }
    }

    const results = Object.entries(wordFrequency).map(([word, frequency]) => ({text: word, size: frequency}));
    console.log(results);
    setData(results);

    setTimeout(() => {
        document.getElementById('word-cloud-container').innerHTML = '';
        document.getElementById('histogram-container').innerHTML = '';
        document.getElementById('piechart-container').innerHTML = '';

        createWordCloud();
        createHistogram();
        createPieChart();
    }, 600);
}

const changeTime = (value, context) => {
    timeRange.value = [dayjs(value[0], {format: 'YYYY-MM-DD'}).toDate(),
        dayjs(value[1], {format: 'YYYY-MM-DD'}).toDate()];

    commitChange();
};
</script>

<template>
    <t-card title="统计/词云" :bordered="false" class="w-full h-full">
        <template #actions>
            <t-date-range-picker clearable size="medium" @change="changeTime"
                                 allowInput class="mr-2"/>
            <t-select autoWidth label="关键词：" class="w-auto inline-block mr-2">
                <t-option v-for="tag in tagsList" :key="tag" :value="tag" :label="tag" @click="changeTag(tag)"/>
            </t-select>
            <t-select autoWidth label="分析对象：" class="w-auto inline-block">
                <t-option-group divider key="raw" label="原生数据">
                    <t-option v-for="content in rawContentList" :key="content" :value="content"
                              :label="content" @click="changeContent(content,true)"/>
                </t-option-group>
            </t-select>
        </template>
        <div class="overflow-scroll h-full">
            <div v-if="content === '' || tag === ''"
                 class="text-center text-gray-500 h-full flex items-center justify-center">
                请选择关键词和分析对象（Content 暂不支持按时间分析）
            </div>
            <div v-else>
                <t-card title="词云" :bordered="false" class="inline-block">
                    <div id="word-cloud-container" class="inline-block"></div>
                </t-card>
                <t-card title="饼图" :bordered="false" class="inline-block">
                    <div id="piechart-container" class="inline-block"></div>
                </t-card>
                <t-card title="柱状图" :bordered="false" class="inline-block">
                    <div id="histogram-container" class="inline-block"></div>
                </t-card>
            </div>
        </div>
    </t-card>
</template>

<style scoped>

</style>