import {createApp} from 'vue'
import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';
import './styles.css';
import App from './App.vue';
import {router} from "@/router";
import echarts from 'echarts';

const app = createApp(App);
app.config.globalProperties.$echarts = echarts;
app
    .use(TDesign)
    .use(router)
    .mount('#app');
