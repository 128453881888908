export async function chat(messageList, apiKey) {
    return await fetch("https://api.aiproxy.io/v1/chat/completions", {
        method: "post",
        // signal: AbortSignal.timeout(8000),
        // 开启后到达设定时间会中断流式输出
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
            model: "claude-instant-1",
            stream: true,
            messages: messageList,
        }),
    });
}
