import {createRouter, createWebHashHistory} from "vue-router";
import MapView from "@/components/MapView.vue";
import AIView from "@/components/AIView.vue";
import Analytics from "@/components/Analytics.vue";

const routes = [{
    path: '/',
    alias: '/map',
    component: MapView
}, {
    path: '/analytics',
    component: Analytics
}, {
    path: '/ai',
    component: AIView
},
];

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})