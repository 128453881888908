<template>
    <div class="flex flex-col h-full">
        <div class="flex-1 mx-2 mb-2" ref="chatListDom">
            <div v-if="messageList.length <= 1" class="h-full text-gray-500">
                暂时没有记录
            </div>
            <div v-else
                 class="group flex flex-col px-4 py-3 hover:bg-slate-100 rounded-lg"
                 v-for="(item, index) of messageList.filter((v) => v.role !== 'system')"
                 :key="index"
            >
                <div class="flex justify-between items-center mb-2">
                    <div class="font-bold">{{ roleAlias[item.role] }}：</div>
                    <Copy class="invisible group-hover:visible" :content="item.content"/>
                </div>
                <div>
                    <div
                        class="prose text-sm text-slate-600 leading-relaxed text-left"
                        v-if="item.content"
                        v-html="md.render(item.content)"
                    ></div>
                    <Loading v-else/>
                </div>
            </div>
        </div>

        <div class="sticky bottom-0 w-full p-6 pb-8 bg-gray-100">
            <div class="-mt-2 mb-2 text-sm text-gray-500" v-if="isConfig">
                请输入 API Key：
            </div>
            <div class="flex">
                <t-select v-model="importVal" placeholder="选择分析主题" class="mr-2 w-60">
                    <t-option :key="index" :label="item" :value="index" v-for="(item, index) of tagsList"
                              @click="selectTheme"/>
                </t-select>
                <input
                    class="pl-2 w-full"
                    :type="isConfig ? 'password' : 'text'"
                    :placeholder="isConfig ? 'sk-xxxxxxxxxx' : '请输入（Ctrl+Enter 发送）'"
                    v-model="messageContent"
                    @keydown.ctrl.enter="isTalking || sendOrSave()"
                />
                <t-button :disabled="isTalking" @click="sendOrSave()"
                          theme="primary" class="ml-3">
                    {{ isConfig ? "保存" : "发送" }}
                </t-button>
                <t-button theme="default" variant="outline"
                          @click="clickConfig()" class="ml-2">设置
                </t-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, watch, nextTick, onMounted} from "vue";
import {chat} from "./libs/claude";
import cryptoJS from "crypto-js";
import Loading from "./Loading.vue";
import Copy from "./Copy.vue";
import {md} from "./libs/markdown";

const tagsList = ["保供", "健身", "压茬", "可乐", "咖啡", "团长", "封校", "小区", "研判", "移民",
    "自治", "辟谣", "刘耕宏", "合围区", "外国人", "必需品", "无差别", "楼组长", "全力以复",
    "历史无阳", "哄抬物价", "外籍人士", "有序放开", "有序解封", "有限流动", "点式复工", "解封不解防"];
const importVal = ref();
let apiKey = "";
let isConfig = ref(true);
let isTalking = ref(false);
let messageContent = ref("");
const chatListDom = ref();
const decoder = new TextDecoder("utf-8");
const roleAlias = {user: "User", assistant: "Claude", system: "System"};
const defaultMessage = {
    role: "system",
    content: "你是一位数据分析师，需要基于以下 CSV 数据进行分析。但现在暂时还没有选择数据，无论用户说什么，都请回复“请先选择主题”。",
};
const messageList = ref([defaultMessage]);

onMounted(() => {
    if (getAPIKey()) {
        switchConfigStatus();
    }
});

function selectTheme() {
    console.log(importVal.value)
    fetch('./csv/新冠 防控政策 ' + tagsList[importVal.value] + '_None_None.csv')
        .then(response => response.text())
        .then(data => {
            messageList.value = [{
                role: "system",
                content: "你是一位数据分析师，需要基于以下 CSV 数据文件进行分析；这是一个数据列表，而非单条数据，请勿只对某一条数据进行分析；请给出较为细致的回应。CSV 原始数据如下：" + data.replaceAll(/https:\/\/[a-zA-Z0-9]+\.sinaimg+\.cn\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\.[a-zA-Z]+/g, "")
            }];
            console.log(messageList.value);
        });
}

const sendChatMessage = async (content = messageContent.value) => {
    try {
        isTalking.value = true;
        if (messageList.value.length === 2) {
            messageList.value.pop();
        }
        messageList.value.push({role: "user", content});
        clearMessageContent();
        messageList.value.push({role: "assistant", content: ""});

        const {body, status} = await chat(messageList.value, getAPIKey());
        if (body) {
            const reader = body.getReader();
            await readStream(reader, status);
        }
    } catch (error) {
        appendLastMessageContent(error);
    } finally {
        isTalking.value = false;
    }
};

const readStream = async (
    reader, status
) => {
    let partialLine = "";

    // eslint-disable-next-line no-constant-condition
    while (true) {
        // eslint-disable-next-line no-await-in-loop
        const {value, done} = await reader.read();
        if (done) break;

        const decodedText = decoder.decode(value, {stream: true});

        if (status !== 200) {
            const json = JSON.parse(decodedText); // start with "data: "
            const content = json.error.message ?? decodedText;
            appendLastMessageContent(content);
            return;
        }

        const chunk = partialLine + decodedText;
        const newLines = chunk.split(/\r?\n/);

        partialLine = newLines.pop() ?? "";

        for (const line of newLines) {
            if (line.length === 0) continue; // ignore empty message
            if (line.startsWith(":")) continue; // ignore sse comment message
            if (line === "data: [DONE]") return; //

            const json = JSON.parse(line.substring(6)); // start with "data: "
            const content =
                status === 200
                    ? json.choices[0].delta.content ?? ""
                    : json.error.message;
            appendLastMessageContent(content);
        }
    }
};

const appendLastMessageContent = (content) =>
    (messageList.value[messageList.value.length - 1].content += content);

const sendOrSave = () => {
    if (!messageContent.value.length) return;
    if (isConfig.value) {
        if (saveAPIKey(messageContent.value.trim())) {
            switchConfigStatus();
        }
        clearMessageContent();
    } else {
        sendChatMessage();
    }
};

const clickConfig = () => {
    if (!isConfig.value) {
        messageContent.value = getAPIKey();
    } else {
        clearMessageContent();
    }
    switchConfigStatus();
};

const getSecretKey = () => "lianginx";

const saveAPIKey = (apiKey) => {
    if (apiKey.slice(0, 3) !== "sk-" || apiKey.length !== 51) {
        alert("API Key 错误，请检查后重新输入！");
        return false;
    }
    const aesAPIKey = cryptoJS.AES.encrypt(apiKey, getSecretKey()).toString();
    localStorage.setItem("apiKey", aesAPIKey);
    return true;
};

const getAPIKey = () => {
    if (apiKey) return apiKey;
    const aesAPIKey = localStorage.getItem("apiKey") ?? "";
    apiKey = cryptoJS.AES.decrypt(aesAPIKey, getSecretKey()).toString(
        cryptoJS.enc.Utf8
    );
    return apiKey;
};

const switchConfigStatus = () => (isConfig.value = !isConfig.value);

const clearMessageContent = () => (messageContent.value = "");

const scrollToBottom = () => {
    if (!chatListDom.value) return;
    scrollTo(0, chatListDom.value.scrollHeight);
};

watch(messageList.value, () => nextTick(() => scrollToBottom()));
</script>

<style scoped>
pre {
    font-family: -apple-system, "Noto Sans", "Helvetica Neue", Helvetica,
    "Nimbus Sans L", Arial, "Liberation Sans", "PingFang SC", "Hiragino Sans GB",
    "Noto Sans CJK SC", "Source Han Sans SC", "Source Han Sans CN",
    "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti",
    SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;
}
</style>
